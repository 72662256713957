import './App.css';
import Footer from './components/Footer';
import Home from './pages/Home/Home';
import Pages from './pages/Pages';

function App() {
  return (
    <>
      <Home/>
      <Pages/>
      <Footer/>
    </>
  );
}

export default App;
